<template>
  <fd-form
    class="job-form p-3 sm-p-2"
    @submit="$emit(submitEventName, payload)"
  >
    <fd-select
      v-model="form.jobScopeId"
      class="col-12 mb-2"
      label="Job Scope"
      :options="jobScopes"
      :optionLabel="(val) => val.title"
      :optionValue="(val) => val.id"
      :validators="[validator.required]"
      :disabled="mode == 'edit'"
    >
    </fd-select>

    <fd-select
      v-model="form.paymentType"
      class="col-12 mb-2"
      label="Payment Type"
      :options="paymentTypeOptions"
      :validators="[validator.required]"
    >
    </fd-select>

    <div
      v-if="isPayByCommission"
      class="bg-orange-100 fg-grey-800 p-2 mb-2"
      :style="{ borderRadius: '8px' }"
    >
      <span class="d-block">
        Job rate will be distributed by 100% of your share.
      </span>
      <span class="d-block">
        Your remaining allocatable commission rate is
      </span>
      <span class="d-block" :style="{ fontSize: '18px' }">
        {{ allocatablePercentage }}%
      </span>
    </div>
    <div v-else class="bg-blue-100 fg-grey-800 p-2 mb-2">
      Fixed price job will not be included in the estimated amount calculation
      in acn job overview.
    </div>

    <div class="bordered-card p-2 mb-3">
      <fd-input
        v-model="form.rates"
        class="col-12 mb-2"
        label="Job Rates"
        name="Pay Rates"
        type="number"
        :validators="[
          validator.required,
	(val) => validator.minValue(val,0),
	...rateMaxValidator
         ]"
      >
      </fd-input>

      <display-data
        label="Calculated Rate"
:content="`${form.rates}%`"
       
      ></display-data>
      <display-data
        label="Estimated Amount"
        :content="
          getJobEstimatedAmount({
            listingCommission: listingCommission,
            jobRates: form.rates,
            specialistCommission: specialistRates,
            salePrice: listing.property.salePrice
          })
        "
      ></display-data>
    </div>

    <fd-textarea
      v-model="form.remark"
      class="col-12 mb-2"
      label="Remark"
      name="jobRemark"
    >
    </fd-textarea>

    <div class="d-flex justify-content-end mt-2">
      <fd-button class="mr-2" @click="$emit('close')">Close</fd-button>
      <fd-button type="submit" class="main">{{ actionLabel }}</fd-button>
    </div>
  </fd-form>
</template>

<script>
import { toTitleCase } from "@/utils/string";
import { acnPaymentType } from "@/enums";
import useACNRates from "@/modules/ACN/composables/useACNRates";

import {
  required,
  minValue,
  maxValue
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  setup() {
    const { getJobCalculatedRate, getJobEstimatedAmount } = useACNRates();
    return { getJobCalculatedRate, getJobEstimatedAmount };
  },
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData")
  },
  mixins: [],
  props: {
    mode: {
      type: String,
      default: "create"
    },
    jobScopes: {
      type: Array,
      required: true
    },
    payload: {
      type: Object
    },
    maxAllocationRates: {
      type: [Number, String],
      required: true
    },
    listing: {
      type: Object
    },
    listingCommission: {
      type: [Number, String]
    },
    specialistRates: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      paymentTypeOptions: this.$mapJsonToArray(acnPaymentType, (e) => ({
        id: e,
        name: e
      })),
      currentRate: 0,

      validator: {
        required,
        minValue
      },

      toTitleCase
    };
  },
  computed: {
    form: {
      get() {
        return this.payload;
      },
      set(val) {
        this.$emit("update:payload", { ...this.form, val });
      }
    },
    isPayByCommission() {
      return this.form.paymentType == acnPaymentType.COMMISSION;
    },
    allocatablePercentage() {    
	return this.specialistRates; 
    },
    rateMaxValidator() {
      if (this.isPayByCommission) {
        return [(val) => maxValue(val, this.allocatablePercentage)];
      } else {
        return [];
      }
    },
    actionLabel() {
      switch (this.mode) {
        case "create":
          return "Create";
        case "edit":
          return "Update";
        default:
          return "";
      }
    },
    submitEventName() {
      switch (this.mode) {
        case "create":
          return "create";
        case "edit":
          return "update";
        default:
          return "";
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.currentRate = this.mode == "edit" ? this.payload.rates : 0;
  },
  methods: {}
};
</script>

<style lang="scss"></style>
