var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fd-form',{staticClass:"job-form p-3 sm-p-2",on:{"submit":function($event){return _vm.$emit(_vm.submitEventName, _vm.payload)}}},[_c('fd-select',{staticClass:"col-12 mb-2",attrs:{"label":"Job Scope","options":_vm.jobScopes,"optionLabel":function (val) { return val.title; },"optionValue":function (val) { return val.id; },"validators":[_vm.validator.required],"disabled":_vm.mode == 'edit'},model:{value:(_vm.form.jobScopeId),callback:function ($$v) {_vm.$set(_vm.form, "jobScopeId", $$v)},expression:"form.jobScopeId"}}),_c('fd-select',{staticClass:"col-12 mb-2",attrs:{"label":"Payment Type","options":_vm.paymentTypeOptions,"validators":[_vm.validator.required]},model:{value:(_vm.form.paymentType),callback:function ($$v) {_vm.$set(_vm.form, "paymentType", $$v)},expression:"form.paymentType"}}),(_vm.isPayByCommission)?_c('div',{staticClass:"bg-orange-100 fg-grey-800 p-2 mb-2",style:({ borderRadius: '8px' })},[_c('span',{staticClass:"d-block"},[_vm._v(" Job rate will be distributed by 100% of your share. ")]),_c('span',{staticClass:"d-block"},[_vm._v(" Your remaining allocatable commission rate is ")]),_c('span',{staticClass:"d-block",style:({ fontSize: '18px' })},[_vm._v(" "+_vm._s(_vm.allocatablePercentage)+"% ")])]):_c('div',{staticClass:"bg-blue-100 fg-grey-800 p-2 mb-2"},[_vm._v(" Fixed price job will not be included in the estimated amount calculation in acn job overview. ")]),_c('div',{staticClass:"bordered-card p-2 mb-3"},[_c('fd-input',{staticClass:"col-12 mb-2",attrs:{"label":"Job Rates","name":"Pay Rates","type":"number","validators":[
          _vm.validator.required,
	function (val) { return _vm.validator.minValue(val,0); } ].concat( _vm.rateMaxValidator
         )},model:{value:(_vm.form.rates),callback:function ($$v) {_vm.$set(_vm.form, "rates", $$v)},expression:"form.rates"}}),_c('display-data',{attrs:{"label":"Calculated Rate","content":((_vm.form.rates) + "%")}}),_c('display-data',{attrs:{"label":"Estimated Amount","content":_vm.getJobEstimatedAmount({
            listingCommission: _vm.listingCommission,
            jobRates: _vm.form.rates,
            specialistCommission: _vm.specialistRates,
            salePrice: _vm.listing.property.salePrice
          })}})],1),_c('fd-textarea',{staticClass:"col-12 mb-2",attrs:{"label":"Remark","name":"jobRemark"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}}),_c('div',{staticClass:"d-flex justify-content-end mt-2"},[_c('fd-button',{staticClass:"mr-2",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Close")]),_c('fd-button',{staticClass:"main",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.actionLabel))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }